<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto">
        <div>
          <p class="text-danger fw-bold text-capitalize" v-if="getErrors.payment">
            {{ getErrors.payment }}
          </p>
          <!-- <CardHeading :heading="paymentStep2Locale.heading" class="mb-2" /> -->
          <h1>
            {{ $t("Registration fee") }}
          </h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "To apply for listings, we ask for a one-time contribution of €5 per main occupant. This contribution ensures that we can safely collect your data from various government agencies."
              )
            }}
          </p>

          <ul class="p-0 mb-4">
            <li>
              <Tick fill="#168838" :width="23" :height="23" class="mr-2" />
              <span class="fw-bold text-dark2">
                {{ $t("One-time contribution") }}
              </span>
            </li>
            <li>
              <Tick fill="#168838" :width="23" :height="23" class="mr-2" />
              <span class="fw-bold text-dark2">
                {{ $t("Fully checked rental passport") }}
              </span>
            </li>
            <li>
              <Tick fill="#168838" :width="23" :height="23" class="mr-2" />
              <span class="fw-bold text-dark2">
                {{ $t("Unlimited acces to listings") }}
              </span>
            </li>
          </ul>
          <div>
            <button
              @click="initiatePayment"
              class="btn btn-primary btn-block"
              :disabled="getLoadings.payment"
            >
              <b-spinner
                v-if="getLoadings.payment"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>
              {{ $t("Create Payment") }}: {{ amount }}
            </button>

            <!-- <img class="ml-2" :src="imgIdeal" alt="" /> -->
            <button class="btn btn-outline-white btn-block" @click="$router.go(-1)">
              {{ $t("Go back") }}
            </button>
          </div>
          <p class="step__lowerText mb-0 mt-3">
            {{ paymentStep2Locale.lowerText }}
          </p>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <div class="step__card">
            <h1 class="step__card__heading">
              {{ $t("Specification:") }}
            </h1>
            <div class="step__card__infoContainer">
              <div>
                <h2 class="step__card__key">
                  {{ $t("Verify your identity") }}
                </h2>
                <hr />
              </div>
              <div>
                <h2 class="step__card__key">
                  {{ $t("Validate your income") }}
                </h2>
                <!-- <p class="step__card__value"></p> -->
                <hr />
              </div>
              <div>
                <h2 class="step__card__key">
                  {{ $t("Check your credit score") }}
                </h2>
                <!-- <p class="step__card__value"></p> -->
                <hr />
              </div>
              <div>
                <h2 class="step__card__key">
                  {{ $t("KYC audit") }}
                </h2>
                <!-- <p class="step__card__value"></p> -->
                <hr />
              </div>
              <div class="d-flex justify-content-between mt-5">
                <img class="w-50" :src="paymentVendor" alt="" />
                <p class="text-right step__card__amount mb-0">{{ amount }},-</p>
              </div>

              <div class="bg-light-gray text-gray w-100 mt-4 text-center">
                <small>
                  <i class="fa-solid fa-lock mr-2"></i>
                  {{ $t("Payment is made via a secure connection") }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import Tick from "@/components/svg/Tick";
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
export default {
  data() {
    return {
      img: require("../../../assets/images/payment/paymentstep1.svg"),
      imgIdeal: require("../../../assets/images/payment/ideal.svg"),
      paymentVendor: require("../../../assets/images/payment/vendor.png"),
    };
  },
  components: {
    Tick,
    RightSection,
    AuthLayoutContent,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),

    paymentStep2Locale() {
      return this.$t("payments.step2");
    },
    cardValues() {
      return [
        {
          key: this.paymentStep2Locale.card.qii,
          value: this.paymentStep2Locale.card.qiiValue,
        },
        {
          key: this.paymentStep2Locale.card.kycCheck,
          value: this.paymentStep2Locale.card.kycValue,
        },
        {
          key: this.paymentStep2Locale.card.creditCheck,
          value: this.paymentStep2Locale.card.creditValue,
        },
      ];
    },
    amount() {
      const numOfTennats = this.user.totalPersons;
      return `€${numOfTennats * 5}`;
    },
  },
  methods: {
    ...mapActions("payment", ["createPayment"]),
    async initiatePayment() {
      try {
        const data = await this.createPayment();
        location.href = data.data.checkoutUrl;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__lowerText {
    @include font(0.875rem, $gray4, 400);
  }

  &__rightSection {
    width: 412px;

    @include xs {
      width: 93%;
    }
  }

  &__card {
    width: 100%;

    padding: 35px 28px;

    @include xs {
      padding: 35px 15px;
    }

    background: #ffffff;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.07);
    border-radius: 4px;

    &__heading {
      @include font(1.375rem, $primary, 500);
    }

    &__infoContainer {
      margin-top: 32px;
    }

    &__key {
      @include font(1.125rem, #000, 700);
    }

    &__value {
      @include font(0.875rem, #000, 500);
    }

    &__amount {
      @include font(1.125rem, #000, 700);
    }
  }

  &__subtext {
    @include font(0.875rem, $alreadyAccountTextColor, 400);
    margin-top: 19px;
  }
}
</style>
